import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/index'
	}, {
		path: '/index',
		name: 'index',
		component: () => import( /* webpackChunkName: "home" */ '../views/home'),
		meta: {
			title: '首页'
		}
	},{
		path: '/detail',
		name: 'detail',
		component: () => import( /* webpackChunkName: "home" */ '../views/home/detail'),
		meta: {
			title: '详情'
		}
	},{
		path: '/news',
		name: 'news',
		component: () => import( /* webpackChunkName: "news" */ '../views/news'),
		meta: {
			title: '安全新闻'
		}
	},
	{
		path: '/rules',
		name: 'rules',
		component: () => import( /* webpackChunkName: "rules" */ '../views/rules'),
		meta: {
			title: '政策法规'
		}
	}, {
		path: '/notices',
		name: 'notices',
		component: () => import( /* webpackChunkName: "notice" */ '../views/notices'),
		meta: {
			title: '通知公告'
		}
	}, {
		path: '/education',
		name: 'education',
		component: () => import( /* webpackChunkName: "education" */ '../views/education'),
		meta: {
			title: '安全教育'
		}
	},
	// {
	// 	path: '/emergency',
	// 	name: 'emergency',
	// 	component: () => import( /* webpackChunkName: "emergency" */ '../views/emergency'),
	// 	meta: {
	// 		title: '应急管理'
	// 	}
	// }, {
	// 	path: '/food',
	// 	name: 'food',
	// 	component: () => import( /* webpackChunkName: "food" */ '../views/food'),
	// 	meta: {
	// 		title: '食品安全'
	// 	}
	// },
	{
		path: '/schools',
		name: 'schools',
		component: () => import( /* webpackChunkName: "school" */ '../views/schools'),
		meta: {
			title: '学校展示'
		}
	}, {
		path: '/aboutme',
		name: 'aboutme',
		component: () => import( /* webpackChunkName: "aboutme" */ '../views/aboutme'),
		meta: {
			title: '关于我们'
		}
	}, {
		path: '/download',
		name: 'download',
		component: () => import( /* webpackChunkName: "download" */ '../views/download'),
		meta: {
			title: 'APP下载'
		}
	}
]

const router = new VueRouter({
	routes
})

export default router
