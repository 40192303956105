<template>
	<div id="app">
		<div class="header-warp">
			<headerWarp></headerWarp>
		</div>

		<div class="main-warp">
			<router-view />
		</div>

		<div class="footer-warp">
			<footerWarp></footerWarp>
		</div>
	</div>
</template>
<script>
	import headerWarp from '@/components/headerWarp'
	import footerWarp from '@/components/footerWarp'

	export default {
		name: "index",
		components: {
			headerWarp,
			footerWarp
		},
		data() {
			return {}
		}
	}
</script>
<style lang="less">
	// html {
	// 	filter: grayscale(100%);
	// 	-moz-filter: grayscale(100%);
	// 	-o-filter: grayscale(100%);
	// 	-webkit-filter: grayscale(1);
	// }

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			cursor: pointer;
		}
	}


	#app {
		font-family: Microsoft YaHei;
		font-weight: 400;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #2c3e50;
		

		// .header-nav {

		// 	//border: red solid 1px;
		// 	.sNav {
		// 		// height: 100%;
		// 	}
		// }

		// .main {
		// 	//border: red solid 1px;
		// 	// height: auto;
		// 	margin-left: 130px;
		// 	margin-right: 130px;
		// 	padding: 5px;
		// }

		// .footer {
		// 	// height: auto;
		// 	margin: 20px;
		// 	padding: 10px;
		// 	margin-bottom: 0;
		// 	text-align: center;
		// 	font-size: small;
		// 	line-height: 1;
		// 	background-color: rgb(32,149,131);
		// 	color: white;
		// }
	}

	// @media (max-width:1000px){
	// 	#app {
	// 		margin: 0 !important;
	// 		padding: 0 !important;
	// 	}
	// 	.header {
	// 		margin: 0 !important;
	// 		padding: 0 !important;
	// 	}
	// 	.main {
	// 		margin: 0 !important;
	// 		padding: 0 !important;
	// 	}
	// 	.footer {
	// 		margin: 0 !important;
	// 		// padding: 0 !important;
	// 		// color: white;
	// 	}

	// }
</style>
