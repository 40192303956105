<template>
	<div id="footerWarp">
		<div class="qrcode">
			<img src="../../assets/qrcode.jpg" />
			<p>【校园风险管控平台】</p>
		</div>
		<div class="copyright">
			<p>Copyright © 2021 成都心安理得安全科技研究院  All Rights Reserved</p>
			<p>版权所有© 成都心安理得安全科技研究院  蜀ICP备2021007568号-1</p>
			<p>地址：中国（四川）自由贸易试验区成都高新区府城大道西段399号7栋3单元3层307号  邮编：610095</p>
		</div>

	</div>
</template>

<script>
	export default {
		name: "footerWarp",
		data() {
			return {
				activeIndex: '1',
				search: ""
			}
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			}
		}
	}
</script>

<style scoped lang="less">
	#footerWarp {
		background: url("../../assets/footerbg.png") no-repeat;
		background-size: cover;
		padding: 168px 100px 42px 100px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		.qrcode {
			width: 180px;
			display: flex;
			flex-direction: column;
			align-items: center;
			img {
				width: 170px;
				height: 170px;
			}
			p {
				margin: 0;
				color: #FFFFFF;
			}
		}

		.copyright {
			width: 100%;
			text-align: center;
			color: #8ED7D1;
		}
	}

</style>
