<template>
    <div id="headerWarp">

        <!-- 顶部黑色条框栏 -->
        <b-navbar toggleable="sm" type="dark" variant="dark" class="navbar">
            <!-- <b-navbar-brand href="#"></b-navbar-brand> -->

            <b-navbar-nav class="phone">
                <b-nav-text><i class="el-icon-phone-outline"></i> {{phone}}</b-nav-text>
                <!-- <b-nav-item><i class="el-icon-phone-outline"></i> {{phone}}</b-nav-item> -->
            </b-navbar-nav>
            <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

            <!-- <b-collapse id="nav-collapse" is-nav> -->

            <b-navbar-nav class="top-right-bar">

                <b-nav-item to="/download" target="_blank"><img src="../../assets/appDownload@2x.png"> APP下载</b-nav-item>
                <b-nav-item class="top-search">
                    <!-- <el-input size="mini" placeholder="请输入内容" suffix-icon="el-icon-search" v-model="search">
                        </el-input> -->
                    <!--					<b-nav-form>-->
                    <!--						<b-form-input size="sm" class="mr-sm-2" placeholder="请输入搜索内容" style="background-color: #555555; border: 1px solid #555555;"></b-form-input>-->
                    <!--						<b-button size="sm" class="my-2 my-sm-0" type="submit"-->
                    <!--							style="background-color: #555555; border: 1px solid #555555; margin: 0;"><i-->
                    <!--								class="el-icon-search" style="color: #cccccc;"></i>-->
                    <!--						</b-button>-->
                    <!--					</b-nav-form>-->
                </b-nav-item>

                <b-nav-item href="http://47.119.161.27/scsp" target="_blank" class="login-btn"><img src="../../assets/login.png">
                    登录
                </b-nav-item>

            </b-navbar-nav>
            <!-- </b-collapse> -->
            <b-button class="open-sidebar" v-b-toggle.sidebar-variant><i class="el-icon-more"></i></b-button>

        </b-navbar>

        <!-- 顶部导航栏 -->
        <div class="logo-menu">
            <div class="logo">
                <el-image :src="require('@/assets/logo@2x.png')"></el-image>
            </div>
            <b-nav small align="right" class="my-menu">
                <b-nav-item to="/index" exact exact-active-class="active">首页
                    <div class="active"></div>
                </b-nav-item>
                <b-nav-item to="/news" exact exact-active-class="active">安全新闻
                    <div class="active"></div>
                </b-nav-item>
                <b-nav-item to="/rules" exact exact-active-class="active">政策法规
                    <div class="active"></div>
                </b-nav-item>
                <b-nav-item to="/notices" exact exact-active-class="active">通知公告
                    <div class="active"></div>
                </b-nav-item>
                <b-nav-item to="/education" exact exact-active-class="active">安全教育
                    <div class="active"></div>
                </b-nav-item>
<!--                <b-nav-item to="/emergency" exact exact-active-class="active">应急管理-->
<!--                    <div class="active"></div>-->
<!--                </b-nav-item>-->
<!--                <b-nav-item to="/food" exact exact-active-class="active">食品安全-->
<!--                    <div class="active"></div>-->
<!--                </b-nav-item>-->
                <b-nav-item to="/schools" exact exact-active-class="active">学校展示
                    <div class="active"></div>
                </b-nav-item>
                <b-nav-item to="/aboutme" exact exact-active-class="active">关于我们
                    <div class="active"></div>
                </b-nav-item>
            </b-nav>
        </div>

        <!-- 侧边导航栏 -->
        <b-sidebar id="sidebar-variant" bg-variant="dark" :backdrop="true" text-variant="light" shadow width="200px">
            <div>
                <b-nav vertical style="text-align: center;">
                    <b-nav-text><i class="el-icon-phone-outline"></i> {{phone}}</b-nav-text>
                    <b-nav-item to="/index" exact exact-active-class="active">首页</b-nav-item>
                    <b-nav-item to="/news" exact exact-active-class="active">安全新闻</b-nav-item>
                    <b-nav-item to="/rules" exact exact-active-class="active">政策法规</b-nav-item>
                    <b-nav-item to="/notices" exact exact-active-class="active">通知公告</b-nav-item>
                    <b-nav-item to="/education" exact exact-active-class="active">安全教育</b-nav-item>
<!--                    <b-nav-item to="/emergency" exact exact-active-class="active">应急管理</b-nav-item>-->
<!--                    <b-nav-item to="/food" exact exact-active-class="active">食品安全</b-nav-item>-->
                    <b-nav-item to="/schools" exact exact-active-class="active">学校展示</b-nav-item>
                    <b-nav-item to="/aboutme" exact exact-active-class="active">关于我们</b-nav-item>
                </b-nav>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    export default {
        name: "headerWarp",
        data() {
            return {
                phone: '400 8001234',
                search: ""
            }
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style scoped lang="less">
    .navbar {
        padding: 0 100px 0 100px;
        height: 35px;
        justify-content: space-between;

        .top-right-bar {
            .nav-item {
                margin-left: 30px;
            }
        }

        .navbar-text {
            font-style: italic;
        }

        .nav-link {
            margin: 0;
            padding: 0;
            line-height: 2;
        }

        .ml-auto {
            .nav-item {
                margin-right: 30px;
            }
        }

        .ml-auto input {
            background-color: #555555 !important;
            border: 0;
        }

        .open-sidebar {
            display: none;
        }
    }

    .b-sidebar {

        // background-color: rgba(0, 0, 0, 0.3) !important;
        a {
            color: white;
            line-height: 3;
        }
    }

    .logo-menu {
        width: 100%;
        /*height: 100px;*/
        padding: 50px 60px 0 60px;
        position: absolute;
        top: 35px;
        z-index: 9;
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;

        .logo {
            display: flex;
            align-items: center;
            color: white;

            // h3 {
            // 	font-weight: 800;
            // }

            // p {
            // 	margin: 0;
            // 	font-style: italic;
            // }

            // .el-image {
            // 	float: left;
            // 	height: 60px;
            // 	width: 60px;
            // 	margin: 10px;
            // }
        }

        .my-menu {
            a {
                position: relative;
                padding: 10px 5px 10px 5px;
                margin-right: 30px;
                font-family: Microsoft YaHei;
                font-size: 16px;
                font-weight: bold;
                color: #FEFEFE;
                text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
                text-align: center;

                .active {
                    position: absolute;
                    left: -5px;
                    width: ~"calc(100% + 10px)";
                    padding: 0 50% 0 -50%;
                    height: 14px;
                    background: transparent;
                    opacity: 0.5;
                    margin-top: -9px;
                    z-index: -1;
                }

            }

            a:hover {
                .active {
                    background: linear-gradient(90deg, #07A597, #44B587);
                }

            }

            a:active {
                .active {
                    background: linear-gradient(90deg, #07A597, #44B587);
                }
            }
        }
    }

    /*@media (max-width: 1200px) {*/
    /*    .logo-menu {*/
    /*        padding-top: 20px;*/

    /*        .logo {*/
    /*            !*display: none !important;*!*/
    /*        }*/

    /*        .my-menu {*/
    /*            a {*/
    /*                font-size: 14px !important;*/
    /*                margin-right: 18px !important;*/
    /*            }*/
    /*        }*/
    /*    }*/

    /*}*/

    @media (max-width: 900px) {

        .phone,
        .logo-menu,
        .part-blur,
        .top-search {
            display: none;
        }

        .navbar {
            justify-content: flex-end;
            padding: 0 10vw 0 0 !important;
        }

        .top-right-bar {
            flex-direction: row;
        }

        .navbar .open-sidebar {
            margin: 0;
            padding: 1px 5px 1px 5px;
            margin-left: 20px;
            display: inline;
            background-color: #555555;
            border: 0;
        }
    }
</style>
