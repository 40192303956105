import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'

import BootstrapVue from 'bootstrap-vue'


// 引入bootstrap-vue
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)


Vue.use(ElementUI)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 校园风险管理平台`;
    window.scrollTo(0,0)
	next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
